// import ActionCable, { Cable, Subscriptions } from 'actioncable';
import ActionCable, { Cable, Subscriptions } from '@rails/actioncable';
import { createConsumer } from "@rails/actioncable"
import { config } from '../config';

export const getWebsocketConnection = () => {
  if (!window.ActionCable) { window.ActionCable = ActionCable }
  
  const websocketUrl = config.websocketRoot;
  
  console.log("Attempting to set up websocket connection to: " + websocketUrl);
 
  if (!window.consumer) { 
    // const consumer = ActionCable.createConsumer(websocketUrl);
    const consumer = createConsumer(websocketUrl);
    window.consumer = consumer; 
  }
  

  return consumer; 
}

export const subscribeToActionCable = (channelName, hostKey, handleReceivedMessage) => {
  console.log("Attempting tell the cable connection to subscribe to channelName \"" + channelName + "\"");
  const consumer = getWebsocketConnection();
  const result = consumer.subscriptions.create(
    { channel: channelName, hostKey: hostKey }, 
    { received: (gameState) => handleReceivedMessage(gameState) }
  )
  window.result = result

  return result
}

export default  getWebsocketConnection;