import { configureStore } from '@reduxjs/toolkit';
import playerReducer from './reducers/playerSlice';
import activityReducer from './reducers/activitySlice';
var store = configureStore({
    reducer: {
        player: playerReducer,
        activity: activityReducer,
    },
});
export default store;
