var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../hooks';
import { subscribeToActionCable } from './WebsocketInteractions';
import { setGamePhaseState, setPlayers, addPlayer, setUserEvents, addUserEvent, setBoards, getCurrentPhase, getCurrentPlayer, updateActivity, } from '../reducers/activitySlice';
import { setUserData } from "../reducers/playerSlice";
var EventListing = function (props) {
    var ue = props.userEvent;
    var player = props.players.find(function (p) { return p.id === ue.playerId; });
    var playerName = player ? player.displayName : "sum1";
    var desc = "".concat(playerName, " ").concat(ue.desc);
    return (_jsx("li", __assign({ className: 'leading-tight mb-2' }, { children: desc })));
};
var PhaseTransitionPopUp = function () {
    var currentPhase = useAppSelector(getCurrentPhase);
    var currentPhaseName = currentPhase ? currentPhase.name : "[ uhhhhhh sumthing wrong ]";
    var currentUrlPath = window.location.pathname;
    var shouldRender = currentPhase &&
        currentPhase.urlPath &&
        currentPhase.urlPath.length > 0 &&
        (currentPhase.urlPath !== currentUrlPath);
    return (_jsx(_Fragment, { children: shouldRender &&
            _jsx("div", __assign({ className: "fixed top-0 left-0 w-full h-full bg-gray-50 bg-opacity-50 flex justify-center items-center z-40" }, { children: _jsxs("div", __assign({ className: "bg-gray-0 rounded-lg p-8 w-96 h-96 z-50 flex flex-col justify-center" }, { children: [_jsx("h2", __assign({ className: 'mb-4' }, { children: "Time 2 switch" })), _jsxs("a", __assign({ href: currentPhase.urlPath, className: 'btn' }, { children: ["Head to ", currentPhaseName] }))] })) })) }));
};
var TurnIndicator = function (props) {
    var isOwnTurn = props.player.id === props.user.id;
    var cssClass = isOwnTurn ? "bg-green-100 text-green-4" : "bg-gray-14";
    var text = isOwnTurn ? "It's your turn" : "It's ".concat(props.player.displayName, "'s turn");
    return (_jsx("div", __assign({ className: "mb-4 py-3 ".concat(cssClass) }, { children: text })));
};
var GameSessionRightSidebar = function (props) {
    var dispatch = useAppDispatch();
    var _a = useState(null), cableSubscriptions = _a[0], setCableSubscriptions = _a[1];
    var urlKey = props.urlKey;
    var channelName = 'GameEventsChannel';
    var phaseState = useAppSelector(function (state) { return state.activity.gamePhaseState; });
    var playingPlayers = useAppSelector(function (state) { return state.activity.players.playing; });
    var userEvents = useAppSelector(function (state) { return state.activity.userEvents; });
    var userData = useAppSelector(function (state) { return state.player.user; });
    var currentPlayer = useAppSelector(getCurrentPlayer);
    var handleReceivedMessage = function (msg) {
        console.log("handleReceivedMessage called with: ");
        console.log(msg);
        if (msg.activity) {
            dispatch(updateActivity(msg.activity));
        }
        else if (msg.player) {
            console.log("Adding player: ", msg);
            dispatch(addPlayer(msg.player));
        }
        else if (msg.gameEvent) {
            console.log("Adding gameEvent: ", msg);
            dispatch(addUserEvent(msg.gameEvent));
        }
        else if (msg.boards) {
            console.log("Updating boards: ", msg.boards);
            dispatch(setBoards(msg.boards));
        }
        else if (msg.phases) {
            console.log("Updating gamePhaseState: ", msg.phases);
            dispatch(setGamePhaseState(msg.phases));
        }
        else {
            console.log("Got something via websocket that I don't know how to handle: ");
            console.log(msg);
        }
    };
    // Bootstrap initial app data from JSON in DOM
    useEffect(function () {
        if ((phaseState.currentPhaseId.length === 0) && (phaseState !== props.gamePhaseState)) {
            dispatch(setGamePhaseState(props.gamePhaseState));
        }
    }, [phaseState, dispatch]);
    useEffect(function () {
        if (!userData.authToken && (userData.authToken !== props.userData.authToken)) {
            dispatch(setUserData(props.userData));
        }
    }, [userData, dispatch]);
    useEffect(function () {
        if ((playingPlayers.length === 0) && (playingPlayers !== props.players)) {
            dispatch(setPlayers(props.players));
        }
    }, [playingPlayers, dispatch]);
    useEffect(function () {
        if ((userEvents.length === 0) && (userEvents !== props.userEvents)) {
            dispatch(setUserEvents(props.userEvents));
        }
    }, [userEvents, dispatch]);
    useEffect(function () {
        if (!cableSubscriptions) {
            var subs = subscribeToActionCable(channelName, urlKey, handleReceivedMessage);
            setCableSubscriptions(subs);
        }
        else {
            console.log("No need to subscribe again");
        }
    }, [cableSubscriptions, channelName, urlKey]);
    return (_jsxs("div", __assign({ className: 'max-h-full flex flex-col ' }, { children: [_jsxs("div", __assign({ className: "mb-4 overflow-y-scroll" }, { children: [_jsx("h3", __assign({ className: 'mb-2' }, { children: "Players" })), _jsx("ul", __assign({ className: "text-left" }, { children: playingPlayers.map(function (player) { return _jsx("li", { children: player.displayName }); }) }))] })), currentPlayer && _jsx(TurnIndicator, { player: currentPlayer, user: userData }), _jsxs("div", __assign({ className: "grow-0 overflow-y-scroll" }, { children: [_jsx("h3", __assign({ className: 'mb-2' }, { children: "Activity" })), _jsx("ul", __assign({ className: 'text-left' }, { children: userEvents.map(function (ue) { return _jsx(EventListing, { userEvent: ue, players: playingPlayers }); }) }))] })), _jsx(PhaseTransitionPopUp, {})] })));
};
export var maybeRenderSidebar = function (store, urlKey) {
    var activityData = window.activityData;
    var sidebarRootEl = document.getElementById("gameSessionRightSidebar");
    var userData = window.userData;
    if (activityData && sidebarRootEl) {
        var sidebarRoot = ReactDOM.createRoot(sidebarRootEl);
        sidebarRoot.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(GameSessionRightSidebar, { gamePhaseState: activityData.gamePhaseState, players: activityData.players, userEvents: activityData.userEvents, userData: userData, urlKey: urlKey }) })));
    }
};
