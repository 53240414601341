import store from './public/store';
import { matchPath } from 'react-router-dom';
import { maybeRenderSidebar } from './public/features/GameSessionRightSidebar';
import { maybeRenderPreGame } from './public/features/PreGame';
import { maybeRenderWordSelection } from './public/features/WordSelection';
import { maybeRenderWordGuessing } from './public/features/WordGuessing';
import { maybeRenderPostGame } from './public/features/PostGame';
import { maybeRenderLandingPage } from './public/features/LandingPage';
document.addEventListener('DOMContentLoaded', function () {
    var match = matchPath({ path: "/play/:urlKey/:gamePhase" }, window.location.pathname);
    var urlKey = match && match.params.urlKey;
    urlKey && maybeRenderSidebar(store, urlKey);
    urlKey && maybeRenderPreGame(store, urlKey);
    urlKey && maybeRenderWordSelection(store, urlKey);
    urlKey && maybeRenderWordGuessing(store, urlKey);
    urlKey && maybeRenderPostGame(store, urlKey);
    maybeRenderLandingPage(store);
});
