var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import config from '../config';
var initialState = {
    user: {
        id: null,
        displayName: null,
        authToken: null,
    },
    apiState: {
        postGameCreation: 'idle',
        postWordSelection: 'idle',
        postGuessSubmission: 'idle',
    },
    letterPool: {
        availableLetters: [],
        validWords: [],
    },
    secretWord: {
        selected: null,
        set: null,
        serverMessage: null,
    },
    nextGuess: {
        word: '',
        serverMessage: null,
    },
    nextGame: null,
};
export var createGame = createAsyncThunk('player/createGame', function (_a, _b) {
    var getState = _b.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, path, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    console.log('createGame thunk');
                    state = getState();
                    path = "".concat(config.apiRoot, "/game_sessions");
                    return [4 /*yield*/, fetch(path, {
                            method: 'POST',
                            cache: "no-cache",
                            headers: {
                                'Authorization': "Bearer ".concat(state.player.user.authToken),
                            },
                        })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _c.sent()];
            }
        });
    });
});
export var startGame = createAsyncThunk('player/startGame', function (args, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, path, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    path = "".concat(config.apiRoot, "/game_sessions/").concat(args.urlKey, "/start");
                    return [4 /*yield*/, fetch(path, {
                            method: 'POST',
                            cache: "no-cache",
                            headers: {
                                'Authorization': "Bearer ".concat(state.player.user.authToken),
                            },
                        })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _b.sent()];
            }
        });
    });
});
export var submitGuess = createAsyncThunk('player/submitGuess', function (args, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, path, formData, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    path = "".concat(config.apiRoot, "/game_sessions/").concat(args.urlKey, "/turn");
                    formData = new FormData();
                    formData.append('game_turn[submission_str]', args.word);
                    return [4 /*yield*/, fetch(path, {
                            method: 'POST',
                            body: formData,
                            cache: "no-cache",
                            headers: {
                                'Authorization': "Bearer ".concat(state.player.user.authToken),
                            },
                        })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _b.sent()];
            }
        });
    });
});
export var submitWordSelection = createAsyncThunk('player/submitWordSelection', function (args, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, path, formData, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    path = "".concat(config.apiRoot, "/game_sessions/").concat(args.urlKey, "/word_selection");
                    formData = new FormData();
                    formData.append('game_participant[word_str]', args.word);
                    return [4 /*yield*/, fetch(path, {
                            method: 'POST',
                            body: formData,
                            cache: "no-cache",
                            headers: {
                                'Authorization': "Bearer ".concat(state.player.user.authToken),
                            },
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.json()];
            }
        });
    });
});
var playerSlice = createSlice({
    name: 'player',
    initialState: initialState,
    reducers: {
        setUserData: function (state, action) {
            var userData = action.payload;
            state.user.id = userData.id;
            state.user.displayName = userData.displayName;
            state.user.authToken = userData.authToken;
            userData.secretWord && (state.secretWord.set = userData.secretWord);
        },
        setSelectedWord: function (state, action) {
            state.secretWord.selected = action.payload;
        },
        setAvailableLetters: function (state, action) {
            state.letterPool.availableLetters = action.payload;
        },
        setValidWords: function (state, action) {
            state.letterPool.validWords = action.payload;
        },
        appendLetterToGuess: function (state, action) {
            var lastGuess = state.nextGuess.word ? state.nextGuess.word : '';
            if (lastGuess.length < 5) {
                state.nextGuess.word = "".concat(lastGuess).concat(action.payload[0]);
            }
        },
        removeLetterFromGuess: function (state) {
            var lastGuess = state.nextGuess.word ? state.nextGuess.word : '';
            if (lastGuess.length > 0) {
                state.nextGuess.word = lastGuess.slice(0, -1);
            }
        },
        clearGuess: function (state) {
            state.nextGuess.word = '';
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(createGame.pending, function (state) {
            state.apiState.postGameCreation = 'pending';
        })
            .addCase(createGame.fulfilled, function (state, action) {
            state.apiState.postGameCreation = 'success';
            var data = action.payload;
            console.log("data: ");
            console.log(data);
            state.nextGame = data.gameSession;
        })
            .addCase(createGame.rejected, function (state) {
            state.apiState.postGameCreation = 'error';
        })
            .addCase(submitGuess.pending, function (state) {
            state.apiState.postGuessSubmission = 'pending';
        })
            .addCase(submitGuess.fulfilled, function (state, action) {
            var data = action.payload;
            if (data.errors) {
                state.apiState.postGuessSubmission = 'error';
                state.nextGuess.serverMessage = data.errors;
            }
            else {
                state.apiState.postGuessSubmission = 'success';
                state.nextGuess.serverMessage = '';
            }
            state.nextGuess.word = '';
        })
            .addCase(submitGuess.rejected, function (state) {
            state.apiState.postGuessSubmission = 'error';
        })
            .addCase(submitWordSelection.pending, function (state) {
            console.log("submitWordSelection.pending");
            state.apiState.postWordSelection = 'pending';
            state.secretWord.serverMessage = null;
        })
            .addCase(submitWordSelection.fulfilled, function (state, action) {
            console.log("submitWordSelection.fulfilled");
            var data = action.payload;
            if (data.errors) {
                state.apiState.postWordSelection = 'error';
                state.secretWord.serverMessage = data.errors;
            }
            else {
                state.apiState.postWordSelection = 'success';
                state.secretWord.set = data.player.secretWord.set;
                state.secretWord.serverMessage = "Your secret word is: ".concat(data.player.secretWord.set);
            }
        })
            .addCase(submitWordSelection.rejected, function (state) {
            console.log("submitWordSelection.rejected");
            state.apiState.postWordSelection = 'error';
        });
    },
});
export var setUserData = (_a = playerSlice.actions, _a.setUserData), setSelectedWord = _a.setSelectedWord, setAvailableLetters = _a.setAvailableLetters, setValidWords = _a.setValidWords, appendLetterToGuess = _a.appendLetterToGuess, removeLetterFromGuess = _a.removeLetterFromGuess, clearGuess = _a.clearGuess;
export default playerSlice.reducer;
