var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    gamePhaseState: {
        id: '',
        urlKey: '',
        currentPhaseId: '',
        phases: [],
    },
    userEvents: [],
    players: {
        watching: [],
        playing: [],
        requiredToAct: [],
        haveActed: [],
    },
    boards: [],
};
var activitySlice = createSlice({
    name: 'activity',
    initialState: initialState,
    reducers: {
        updateActivity: function (state, action) {
            var _a;
            var activity = action.payload;
            if ((_a = activity.players) === null || _a === void 0 ? void 0 : _a.playing)
                state.players.playing = activity.players.playing;
            if (activity.boards)
                state.boards = activity.boards;
            if (activity.gamePhaseState)
                state.gamePhaseState = activity.gamePhaseState;
            if (activity.userEvents)
                state.userEvents = activity.userEvents;
        },
        setGamePhaseState: function (state, action) {
            state.gamePhaseState = action.payload;
        },
        setPlayers: function (state, action) {
            state.players = action.payload;
        },
        setUserEvents: function (state, action) {
            state.userEvents = action.payload;
        },
        setBoards: function (state, action) {
            state.boards = action.payload;
        },
        addPlayer: function (state, action) {
            var player = action.payload;
            var oldPlayers = state.players.playing;
            state.players.playing = __spreadArray([player], oldPlayers, true);
        },
        addUserEvent: function (state, action) {
            var event = action.payload;
            var oldEvents = state.userEvents;
            state.userEvents = __spreadArray([event], oldEvents, true);
        },
    },
    extraReducers: function (builder) {
        builder;
    },
});
export var getCurrentPhase = function (state) {
    var phaseId = state.activity.gamePhaseState.currentPhaseId;
    var curPhase = null;
    if (phaseId) {
        curPhase = state.activity.gamePhaseState.phases.find(function (ph) { return (ph.id === phaseId); });
    }
    return curPhase;
};
export var getWordGuessingPhase = function (state) {
    var curPhase = null;
    curPhase = state.activity.gamePhaseState.phases.find(function (ph) { return (ph.name === "WordGuessing"); });
    return curPhase;
};
export var getCurrentTurn = function (state) {
    var curPhase = getCurrentPhase(state);
    var curTurn = null;
    if (curPhase) {
        var turnId_1 = curPhase.currentTurnId;
        curTurn = curPhase.turns.find(function (t) { return (t.id === turnId_1); });
    }
    return curTurn;
};
export var getCurrentPlayer = function (state) {
    var curTurn = getCurrentTurn(state);
    var curPlayer = null;
    if (curTurn) {
        var playerId_1 = curTurn.playerId;
        curPlayer = state.activity.players.playing.find(function (p) { return (p.id === playerId_1); });
    }
    return curPlayer;
};
export var getGuesses = function (state) {
    // const phase = getCurrentPhase(state);
    var phase = getWordGuessingPhase(state);
    var guesses = phase === null || phase === void 0 ? void 0 : phase.turns.map(function (turn) { return turn.submission; }).filter(function (guess) { return guess && guess !== ''; });
    return guesses;
};
export var getPositionedLetters = function (state, boardId) {
    var board = state.activity.boards.find(function (b) { return (b.playerId === boardId); });
    return board === null || board === void 0 ? void 0 : board.positionedLetters;
};
export var getPresentLetters = function (state, boardId) {
    var board = state.activity.boards.find(function (b) { return (b.playerId === boardId); });
    return board === null || board === void 0 ? void 0 : board.presentLetters;
};
export var getGuessesAsLetterGuesses = function (state, board) {
    var phase = getCurrentPhase(state);
    var gs = phase === null || phase === void 0 ? void 0 : phase.turns.map(function (turn) { return turn.submission; }).filter(function (guess) { return guess && guess !== ''; });
    var guesses = gs === null || gs === void 0 ? void 0 : gs.map(function (guess) {
        var letters = guess.split('');
        return letters.map(function (letter) {
            return {
                letter: letter,
                accuracy: 'notInWord',
            };
        });
    });
    if (!guesses) {
        guesses = [];
    }
    return guesses;
};
export var setGamePhaseState = (_a = activitySlice.actions, _a.setGamePhaseState), setPlayers = _a.setPlayers, addPlayer = _a.addPlayer, setUserEvents = _a.setUserEvents, addUserEvent = _a.addUserEvent, setBoards = _a.setBoards, updateActivity = _a.updateActivity;
export default activitySlice.reducer;
