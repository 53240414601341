var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { useAppDispatch } from '../hooks';
import { startGame } from '../reducers/playerSlice';
var StartTheGameButton = function (props) {
    var dispatch = useAppDispatch();
    var ch = function (e) {
        e.preventDefault();
        console.log("heard thine clicc!");
        dispatch(startGame({ urlKey: props.urlKey }));
    };
    return (_jsx("button", __assign({ className: "btn", onClick: ch }, { children: "Start the game" })));
};
var MaybeStartTheGameWidget = function (props) {
    return (_jsxs("div", { children: [_jsx("p", { children: "You can see the other players in the sidebar on the right." }), _jsx("p", __assign({ className: "mb-4" }, { children: "When everyone is here, you can" })), _jsx("div", { children: _jsx(StartTheGameButton, { urlKey: props.urlKey }) })] }));
};
export var maybeRenderPreGame = function (store, urlKey) {
    // const preGameData = window.preGameData;
    var preGameRootEl = document.getElementById("preGameRootEl");
    // if (preGameData && preGameRootEl) {
    if (preGameRootEl) {
        var preGameRoot = ReactDOM.createRoot(preGameRootEl);
        preGameRoot.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(MaybeStartTheGameWidget, { urlKey: urlKey }) })));
    }
};
