var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../hooks';
import { submitWordSelection, } from '../reducers/playerSlice';
// const WordPill = (props: WordProps) => {
//   const dispatch = useAppDispatch();
//   const selectedWord: string | null = useAppSelector(state => state.player.secretWord.selected)
//   const ch = (e: any) => {
//     dispatch(setSelectedWord(props.word));
//   }
//   const cssClass = selectedWord === props.word ? "selected pill uppercase" : "pill uppercase";
//   return (
//     <a className={cssClass} href='#' onClick={ch}>
//       {props.word}
//     </a>
//   )
// }
// const WordPills = (props: WordListProps) => {
//   return (
//     <div className="py-4">
//       <h2 className="mb-2">Your secret word</h2>
//       {props.words.map((word) => { return <WordPill word={word} /> })}
//     </div>
//   )
// }
var IndividualLetterDisplay = function (props) {
    return (_jsx("div", __assign({ className: 'letter tile' }, { children: props.letter })));
};
var LetterPoolDisplay = function (props) {
    console.log("LetterPoolDisplay");
    var letters = props.letters;
    return (_jsxs("div", { children: [_jsx("h2", { children: "Your letters" }), _jsx("div", __assign({ className: "py-4 mx-auto max-w-md" }, { children: _jsx("table", __assign({ className: "game-board" }, { children: _jsx("tbody", { children: _jsx("tr", {}) }) })) })), _jsx("p", { children: "These are your unique random letters. The other players have different random letters." }), _jsx("p", { children: "The word you select must be made up of these letters." })] }));
};
var WordForm = function (props) {
    console.log("WordForm");
    var defaultButtonText = 'Select a word to continue';
    var _a = useState(''), word = _a[0], setWord = _a[1];
    var _b = useState(defaultButtonText), buttonText = _b[0], setButtonText = _b[1];
    var _c = useState(false), canSubmit = _c[0], setCanSubmit = _c[1];
    var _d = useState(true), buttonDisabled = _d[0], setButtonDisabled = _d[1];
    var _e = useState(true), renderButton = _e[0], setRenderButton = _e[1];
    var _f = useState(''), msgCss = _f[0], setMsgCss = _f[1];
    var serverMessage = useAppSelector(function (state) { return state.player.secretWord.serverMessage; });
    var apiState = useAppSelector(function (state) { return state.player.apiState.postWordSelection; });
    var dispatch = useAppDispatch();
    var handleWordChange = function (e) {
        setWord(e.target.value);
    };
    useEffect(function () {
        if (apiState === 'success') {
            setRenderButton(false);
            setMsgCss('bg-green-100');
        }
        else {
            setRenderButton(true);
            setMsgCss('bg-red-100');
        }
    }, [apiState]);
    useEffect(function () {
        if (word.length === 5) {
            setCanSubmit(true);
            setButtonText("Play with \"".concat(word, "\""));
            setButtonDisabled(false);
        }
        else {
            setCanSubmit(false);
            setButtonDisabled(true);
            setButtonText(defaultButtonText);
        }
    }, [word]);
    var submitWord = function (e) {
        e.preventDefault();
        if (canSubmit) {
            dispatch(submitWordSelection({
                word: word,
                urlKey: props.urlKey,
            }));
        }
    };
    return (_jsxs("div", __assign({ className: "py-4" }, { children: [_jsx("h2", __assign({ className: "mb-2" }, { children: "Your secret word" })), _jsxs("form", __assign({ onSubmit: submitWord }, { children: [_jsx("input", { onChange: handleWordChange, className: 'mt-4 mb-8 p-1 text-center text-5xl border border-gray-50 rounded-sm w-full uppercase', type: "text", name: "secretWord", placeholder: "adieu", required: true }), serverMessage && _jsx("div", __assign({ className: "".concat(msgCss, " py-2 text-gray-0") }, { children: serverMessage })), renderButton &&
                        _jsx("input", { type: "submit", disabled: buttonDisabled, value: buttonText, className: "btn w-full", style: { margin: '1rem auto' } })] }))] })));
};
export var WordSelectionPage = function (props) {
    return (_jsxs("div", { children: [_jsx(LetterPoolDisplay, { letters: props.letters }), _jsx(WordForm, { urlKey: props.urlKey })] }));
};
// const SubmitWordButton = (props: { word: string, urlKey: string }) => {
//   const dispatch = useAppDispatch();
//   const ch = (e: any) => {
//     console.log("submitting word selection")
//     e.preventDefault();
//     dispatch(submitWordSelection({
//       word: props.word,
//       urlKey: props.urlKey,
//     }));
//   }
//   return (
//     <button className="btn" type="submit" onClick={ch}>
//       Play with "{props.word}"
//     </button>
//   )
// }
// const SelectAWordMessage = () => {
//   return (<div>Select a word to continue</div>)
// }
// const NextStepButton = (props: { urlKey: string }) => {
//   const setWord: string | null = useAppSelector(state => state.player.secretWord.set)
//   const selectedWord: string | null = useAppSelector(state => state.player.secretWord.selected)
//   return (
//     (
//       setWord ? <div>Waiting for other players to select their words</div> : 
//        ( !!selectedWord) ? <SubmitWordButton word={selectedWord} urlKey={props.urlKey} /> : <SelectAWordMessage /> ) 
//   )
// }
// const WordSelectionWidget = (props: WordListProps) => {
//   return (
//     <div>
//       <WordPills urlKey={props.urlKey} words={props.words}/>
//       <NextStepButton urlKey={props.urlKey} />
//     </div>
//   )
// }
export var maybeRenderWordSelection = function (store, urlKey) {
    var wordSelectionData = window.wordSelectionData;
    var wordSelectionRootEl = document.getElementById("wordSelectionWidget");
    console.log("Hi from maybeRenderWordSelection");
    if (wordSelectionData && wordSelectionRootEl) {
        var letters = wordSelectionData.letters;
        var wordSelectionRoot = ReactDOM.createRoot(wordSelectionRootEl);
        wordSelectionRoot.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(WordSelectionPage, { urlKey: urlKey, letters: letters }) })));
    }
    else {
        console.log("Not rendering word selection. Missing one of:");
        console.log("wordSelectionData && wordSelectionRootEl && match && match.params.urlKey");
    }
};
