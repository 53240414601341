var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../hooks';
import GameBoardPanel from '../components/GameBoard';
import { setBoards, getGuesses, } from '../reducers/activitySlice';
export var StaticPostGameView = function (props) {
    var dispatch = useAppDispatch();
    var boardData = useAppSelector(function (state) { return state.activity.boards; });
    var pastGuesses = useAppSelector(getGuesses) || [];
    var nextGuess = useAppSelector(function (state) { return state.player.nextGuess.word; });
    var guessData = {
        pastGuesses: pastGuesses,
        nextGuess: nextGuess
    };
    useEffect(function () {
        if ((boardData.length === 0) && (boardData !== props.boards)) {
            dispatch(setBoards(props.boards));
        }
    }, [boardData, dispatch]);
    return (_jsx("div", { children: _jsx("div", __assign({ className: "flex overflow-x-scroll", style: { maxHeight: "66.7vh" } }, { children: props.boards.map(function (board, i) { return _jsx(GameBoardPanel, { index: i, board: board, guesses: guessData }, board.playerId); }) })) }));
};
export var maybeRenderPostGame = function (store, urlKey) {
    var postGameData = window.postGameData;
    var postGameRootEl = document.getElementById("postGameRoot");
    if (postGameData && postGameRootEl) {
        var wordSelectionRoot = ReactDOM.createRoot(postGameRootEl);
        wordSelectionRoot.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(StaticPostGameView, { urlKey: urlKey, guesses: postGameData.guesses, boards: postGameData.boards }) })));
    }
    else {
        console.log("Not rendering postGame. Missing one of:");
        console.log("postGameData && postGameRootEl");
    }
};
