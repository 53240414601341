var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../hooks';
import { setBoards, getGuesses, getCurrentPlayer, } from '../reducers/activitySlice';
import { submitGuess, clearGuess } from '../reducers/playerSlice';
import GameBoardPanel from '../components/GameBoard';
import Keyboard from '../components/Keyboard';
var Cell = function (props) {
    return (_jsx("td", __assign({ className: props.accuracy }, { children: _jsx("div", __assign({ className: "uppercase" }, { children: props.letter })) })));
};
var Row = function (props) {
    var guessedLetters = props.guess;
    var cellCount = 5;
    var guessedLetterCount = guessedLetters.length;
    var emptyCellCount = cellCount - guessedLetterCount;
    if (emptyCellCount < 0) {
        emptyCellCount = 0;
    }
    var emptyCells = __spreadArray([], Array(emptyCellCount), true).map(function (x, i) {
        return { letter: '', accuracy: 'notGuessedYet' };
    });
    var cells = __spreadArray(__spreadArray([], guessedLetters, true), emptyCells, true);
    return (_jsx("tr", { children: cells.map(function (letterGuess, i) {
            return _jsx(Cell, { letter: letterGuess.letter, accuracy: letterGuess.accuracy }, i);
        }) }));
};
// export const BoardUI = (props: BoardUIProps) => {
//   const pastGuesses = props.guesses.pastGuesses;
//   const nextGuess = props.guesses.nextGuess;
//   const turnEliminated = props.board.turnEliminated;
//   const playerId = props.board.playerId;
//   const players = useAppSelector(state => state.activity.players.playing);
//   const player = players.find((p: Player) => { return ( p.id === playerId)})
//   const board = useAppSelector(state => state.activity.boards.find((b: GameBoard) => { return ( b.playerId === playerId)}));
//   const selfPlayer = useAppSelector(state => state.player?.user);
//   const selfId = selfPlayer?.id;
//   const playerNum = typeof(props.index) === 'number' ? (props.index + 1) : 0;
//   const isOwnBoard = (selfId === playerId);
//   const secretWord = isOwnBoard ? useAppSelector(state => state.player?.secretWord.set) : "";
//   // Empty rows
//   const minRowCount = 8;
//   const guessRowCount = [...pastGuesses, nextGuess].length;
//   let emptyRowCount = minRowCount - guessRowCount;
//   if (emptyRowCount < 0) { emptyRowCount = 2; }
//   const emptyArrays = [...Array(emptyRowCount)];
//   const emptyRows: LetterGuess[][] = emptyArrays.map((x, i) => { 
//     return [
//       {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
//       {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
//       {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
//       {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
//       {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
//     ] 
//   });
//   // const pastGuessesAsLetterGuesses: LetterGuess[][] = pastGuesses.map((guess) => {
//   //   const guessLetters = guess.split('');
//   //   console.log(`guessLetters: ${guessLetters}`); 
//   //   const lgs = guessLetters.map((l, i) => {
//   //     const letter = l as Letter; 
//   //     // const positioned = props.board.positionedLetters[i] === letter;
//   //     // const present = props.board.presentLetters.includes(letter);
//   //     const positioned = board?.positionedLetters[i] === letter;
//   //     const present    = board?.presentLetters.includes(letter);
//   //     console.log(`testing: ${letter} | positioned: ${positioned} | present: ${present}`);
//   //     const accuracy: LetterPlacementAccuracy = positioned ? 'correct' : ( present ? 'wrongPosition' : 'notInWord' ); 
//   //     const lg = { letter: letter, accuracy: accuracy } as LetterGuess;
//   //     return lg;
//   //   });
//   //   return lgs;
//   // });
//   const pastGuessesAsLetterGuesses: LetterGuess[][] = pastGuesses.map((guess, i) => {
//     const guessLetters = guess.split('');
//     const pls: Letter[] = board?.presentLetters[i] || [];
//     let presentLetters = [...pls];
//     // console.log(`guessLetters: ${guessLetters}`); 
//     const lgs = guessLetters.map((l, i) => {
//       const letter = l as Letter; 
//       const positioned = board?.positionedLetters[i] === letter;
//       const present    = presentLetters.includes(letter);
//       // console.log(`testing: ${letter} | positioned: ${positioned} | present: ${present} | presentLetters: ${presentLetters}`);
//       if (present) {
//         presentLetters.splice(presentLetters.indexOf(letter), 1);
//       }
//       const accuracy: LetterPlacementAccuracy = positioned ? 'correct' : ( present ? 'wrongPosition' : 'notInWord' ); 
//       const lg = { letter: letter, accuracy: accuracy } as LetterGuess;
//       return lg;
//     });
//     return lgs;
//   });
//   const nextGuessAsLetterGuesses: LetterGuess[] = nextGuess.split('').map((letter) => {
//     return { letter: letter, accuracy: 'notInWord' }
//   });
//   let rowData = [...pastGuessesAsLetterGuesses, nextGuessAsLetterGuesses, ...emptyRows];
//   if (turnEliminated) {
//     rowData = rowData.slice(0, (turnEliminated + 1));
//   }
//   return (
//     <div className="max-w-xs mb-16 mx-auto" style={{width: "20rem"}}>
//       <div className="mb-4">
//         {/* <h3 className="text-xl">Player {playerNum}{isOwnBoard && " ( you )"}</h3> */}
//         <h3 className="text-xl">{player?.displayName}{secretWord && <>&emsp;(&ldquo;{secretWord}&rdquo;)</>}</h3>
//       </div>
//       <table className="game-board mr-8 mb-8" style={{width: "16rem"}}>
//         <tbody>
//           {rowData.map((guess, i) => { return <Row key={i} guess={guess} /> })}
//         </tbody>
//       </table>
//       {turnEliminated && <div className="text-center text-gray-500">{player?.displayName} eliminated on turn {(turnEliminated + 1)}</div>}
//     </div>
//   )
// }
var SubmitGuessButton = function (props) {
    var dispatch = useAppDispatch();
    var currentUser = useAppSelector(function (state) { return state.player.user; });
    var playerWhoseTurnItIs = useAppSelector(getCurrentPlayer);
    var word = props.word;
    var canSubmitGuess = word.length === 5 && (playerWhoseTurnItIs === null || playerWhoseTurnItIs === void 0 ? void 0 : playerWhoseTurnItIs.id) === currentUser.id;
    var handleClick = function () {
        if (canSubmitGuess) {
            console.log("dispatching submitGuess");
            dispatch(submitGuess({ word: word, urlKey: props.urlKey }));
            dispatch(clearGuess);
        }
    };
    return (_jsxs("button", __assign({ className: "btn btn-primary mb-4", type: "button", onClick: handleClick, disabled: !canSubmitGuess }, { children: ["Guess \u201C", props.word, "\u201D"] })));
};
export var WordGuessingUI = function (props) {
    var dispatch = useAppDispatch();
    console.log("WordGuessingUI");
    var boardData = useAppSelector(function (state) { return state.activity.boards; });
    var pastGuesses = useAppSelector(getGuesses) || [];
    var nextGuess = useAppSelector(function (state) { return state.player.nextGuess.word; });
    var serverMessage = useAppSelector(function (state) { return state.player.nextGuess.serverMessage; });
    var _a = useState(''), msgCss = _a[0], setMsgCss = _a[1];
    var apiState = useAppSelector(function (state) { return state.player.apiState.postGuessSubmission; });
    var guessData = {
        pastGuesses: pastGuesses,
        nextGuess: nextGuess
    };
    // TODO - must be this player's turn
    var canSubmitGuess = nextGuess.length === 5;
    useEffect(function () {
        if ((boardData.length === 0) && (boardData !== props.boards)) {
            dispatch(setBoards(props.boards));
        }
    }, [boardData, dispatch]);
    useEffect(function () {
        if (apiState === 'success') {
            setMsgCss('bg-green-100');
        }
        else {
            setMsgCss('bg-red-100');
        }
    }, [apiState]);
    return (_jsxs("div", __assign({ className: "flex flex-col justify-between h-full py-4" }, { children: [_jsx("div", __assign({ className: "flex overflow-x-scroll", style: { maxHeight: "66.7vh" } }, { children: props.boards.map(function (board, i) { return _jsx(GameBoardPanel, { index: i, board: board, guesses: guessData }, board.playerId); }) })), _jsxs("div", { children: [canSubmitGuess && _jsx(SubmitGuessButton, { word: nextGuess, urlKey: props.urlKey }), serverMessage && _jsx("div", __assign({ className: "".concat(msgCss, " py-2 text-gray-0") }, { children: serverMessage })), _jsx(Keyboard, {})] })] })));
};
export var maybeRenderWordGuessing = function (store, urlKey) {
    var wordGuessingData = window.wordGuessingData;
    var wordGuessingRootEl = document.getElementById("wordGuessingRoot");
    if (wordGuessingData && wordGuessingRootEl) {
        var wordSelectionRoot = ReactDOM.createRoot(wordGuessingRootEl);
        wordSelectionRoot.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(WordGuessingUI, { urlKey: urlKey, guesses: wordGuessingData.guesses, boards: wordGuessingData.boards }) })));
    }
};
