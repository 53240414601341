var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../hooks';
import { getCurrentPlayer, } from '../reducers/activitySlice';
var SecretWordWidget = function (props) {
    var content;
    var style = {};
    if (props.secretWord === null) {
        content = _jsx(_Fragment, { children: "?????" });
        style = { backgroundColor: '#c2c9d1' };
    }
    else {
        content = _jsxs(_Fragment, { children: ["\u201C", props.secretWord, "\u201D"] });
        style = { backgroundColor: '#cbc6c3' };
    }
    style.letterSpacing = '2px';
    return (_jsx("div", __assign({ className: 'text-sm px-2 py-2 uppercase shadow-inner', style: style }, { children: content })));
};
var BoardHeader = function (props) {
    var selfSecretWord = useAppSelector(function (state) { var _a, _b; return (_b = (_a = state.player) === null || _a === void 0 ? void 0 : _a.secretWord) === null || _b === void 0 ? void 0 : _b.set; });
    var player = props.player, isOwnBoard = props.isOwnBoard, revealedWord = props.revealedWord;
    var secretWord = isOwnBoard ? selfSecretWord : revealedWord;
    return (_jsxs("div", __assign({ className: "mb-4" }, { children: [_jsx("h3", __assign({ className: "uppercase mb-2", style: { letterSpacing: '3px' } }, { children: player.displayName })), _jsx("div", __assign({ className: 'px-8' }, { children: _jsx(SecretWordWidget, { secretWord: secretWord || null }) }))] })));
};
var PlayerStatus = function (props) {
    var player = props.player, isOwnBoard = props.isOwnBoard, turnEliminated = props.turnEliminated;
    var selfSecretWord = useAppSelector(function (state) { var _a, _b; return (_b = (_a = state.player) === null || _a === void 0 ? void 0 : _a.secretWord) === null || _b === void 0 ? void 0 : _b.set; });
    var playerWhoseTurnItIs = useAppSelector(getCurrentPlayer);
    var secretWord = props.isOwnBoard ? selfSecretWord : null;
    var playerName = isOwnBoard ? 'You' : player.displayName;
    var beVerb = isOwnBoard ? 'were' : 'was';
    var isThisPlayersTurn = (playerWhoseTurnItIs === null || playerWhoseTurnItIs === void 0 ? void 0 : playerWhoseTurnItIs.id) === player.id;
    var isOwnTurn = isThisPlayersTurn && isOwnBoard;
    // const cssClass = isOwnTurn ? "bg-green-100 text-green-4" : "bg-gray-14";
    // const turnText = isOwnTurn ? "It's your turn" : `It's ${props.player.displayName}'s turn`;
    var displayText = '';
    var cssClasses = '';
    var styles = {};
    if (turnEliminated) {
        displayText = "".concat(playerName, " ").concat(beVerb, " eliminated on turn ").concat((turnEliminated + 1));
        cssClasses = 'text-gray-500';
        styles = { backgroundColor: '#848d98', color: '#f9fcff' };
        // styles = { backgroundColor: '#ff9d71', color: '#6a2506' };
    }
    else if (isThisPlayersTurn) {
        cssClasses = isOwnTurn ? "bg-green-80 text-green-4" : "bg-gray-14";
        displayText = isOwnTurn ? "It's your turn" : "It's ".concat(props.player.displayName, "'s turn");
        styles = isOwnTurn ? { backgroundColor: '#51c68e' } : { backgroundColor: '#dcdcdc' }; // NOTE this is a nice green
    }
    else {
        cssClasses = "bg-gray-14";
        displayText = isOwnBoard ? 'You' : '';
        styles = isOwnBoard ? { backgroundColor: 'rgb(228 223 219)' } : { backgroundColor: '#dad6d3' };
    }
    return (_jsx("div", __assign({ className: "h-10 text-center py-2 rounded-t-lg ".concat(cssClasses), style: styles }, { children: displayText })));
};
var Cell = function (props) {
    return (_jsx("td", __assign({ className: props.accuracy }, { children: _jsx("div", __assign({ className: "letter tile" }, { children: props.letter })) })));
};
var Row = function (props) {
    var guessedLetters = props.guess;
    var cellCount = 5;
    var guessedLetterCount = guessedLetters.length;
    var emptyCellCount = cellCount - guessedLetterCount;
    if (emptyCellCount < 0) {
        emptyCellCount = 0;
    }
    var emptyCells = __spreadArray([], Array(emptyCellCount), true).map(function (x, i) {
        return { letter: '', accuracy: 'notGuessedYet' };
    });
    var cells = __spreadArray(__spreadArray([], guessedLetters, true), emptyCells, true);
    return (_jsx("tr", { children: cells.map(function (letterGuess, i) {
            return _jsx(Cell, { letter: letterGuess.letter, accuracy: letterGuess.accuracy }, i);
        }) }));
};
export var GameBoardPanel = function (props) {
    var pastGuesses = props.guesses.pastGuesses;
    var nextGuess = props.guesses.nextGuess;
    var turnEliminated = props.board.turnEliminated;
    var playerId = props.board.playerId;
    // Self
    var self = useAppSelector(function (state) { return state.player; });
    var selfPlayer = useAppSelector(function (state) { var _a; return (_a = state.player) === null || _a === void 0 ? void 0 : _a.user; });
    var selfId = selfPlayer === null || selfPlayer === void 0 ? void 0 : selfPlayer.id;
    // Other players
    var players = useAppSelector(function (state) { return state.activity.players.playing; });
    var player = players.find(function (p) { return (p.id === playerId); });
    var board = useAppSelector(function (state) { return state.activity.boards.find(function (b) { return (b.playerId === playerId); }); });
    var revealedWord = (board || { revealedWord: null }).revealedWord;
    var playerNum = typeof (props.index) === 'number' ? (props.index + 1) : 0;
    var isOwnBoard = (selfId === playerId);
    // Empty rows
    var minRowCount = 8;
    var guessRowCount = __spreadArray(__spreadArray([], pastGuesses, true), [nextGuess], false).length;
    var emptyRowCount = minRowCount - guessRowCount;
    if (emptyRowCount < 0) {
        emptyRowCount = 2;
    }
    var emptyArrays = __spreadArray([], Array(emptyRowCount), true);
    var emptyRows = emptyArrays.map(function (x, i) {
        return [
            { letter: '', accuracy: 'notGuessedYet' },
            { letter: '', accuracy: 'notGuessedYet' },
            { letter: '', accuracy: 'notGuessedYet' },
            { letter: '', accuracy: 'notGuessedYet' },
            { letter: '', accuracy: 'notGuessedYet' },
        ];
    });
    var pastGuessesAsLetterGuesses = pastGuesses.map(function (guess, i) {
        var guessLetters = guess.split('');
        var pls = (board === null || board === void 0 ? void 0 : board.presentLetters[i]) || [];
        var presentLetters = __spreadArray([], pls, true);
        console.log("guessLetters: ".concat(guessLetters));
        var lgs = guessLetters.map(function (l, i) {
            var letter = l;
            var positioned = (board === null || board === void 0 ? void 0 : board.positionedLetters[i]) === letter;
            var present = presentLetters.includes(letter);
            console.log("testing: ".concat(letter, " | positioned: ").concat(positioned, " | present: ").concat(present, " | presentLetters: ").concat(presentLetters));
            if (present) {
                presentLetters.splice(presentLetters.indexOf(letter), 1);
            }
            var accuracy = positioned ? 'correct' : (present ? 'wrongPosition' : 'notInWord');
            var lg = { letter: letter, accuracy: accuracy };
            return lg;
        });
        return lgs;
    });
    var nextGuessAsLetterGuesses = nextGuess.split('').map(function (letter) {
        return { letter: letter, accuracy: 'notInWord' };
    });
    var rowData = __spreadArray(__spreadArray(__spreadArray([], pastGuessesAsLetterGuesses, true), [nextGuessAsLetterGuesses], false), emptyRows, true);
    if (turnEliminated) {
        rowData = rowData.slice(0, (turnEliminated + 1));
    }
    if (player) {
        return (_jsxs("div", __assign({ className: "gameboard-panel max-w-xs mb-16 mx-auto shadow-lg rounded-lg" }, { children: [_jsx(PlayerStatus, { isOwnBoard: isOwnBoard, player: player, turnEliminated: turnEliminated }), _jsxs("div", __assign({ className: "gameboard-content pt-4 pb-8 rounded-b-lg", style: { backgroundColor: '#dad6d3' } }, { children: [_jsx(BoardHeader, { player: player, isOwnBoard: isOwnBoard, revealedWord: revealedWord }), _jsx("table", __assign({ className: "game-board mr-8 mb-8 border-collapse" }, { children: _jsx("tbody", { children: rowData.map(function (guess, i) { return _jsx(Row, { guess: guess }, i); }) }) }))] }))] })));
    }
    else {
        return _jsx("div", { children: "Something went wrong!" });
    }
};
export default GameBoardPanel;
