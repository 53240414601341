var origin = document.location.origin;
var host = document.location.host;
var isLocal = host.includes('localhost') || host.includes('lvh.me');
var isProduction = !isLocal;
var domain = isLocal ? 'lvh.me' : 'guessticle.com';
var websiteRoot = isLocal ? "http://".concat(domain, ":3000") : "https://".concat(domain);
var apiRoot = isLocal ? "http://api.".concat(domain, ":3000/v0") : "https://api.".concat(domain, "/v0");
var websocketRoot = isLocal ? "ws://api.".concat(domain, ":3000/cable") : "wss://api.".concat(domain, "/cable");
var appName = "Guessticle";
export var config = {
    env: isProduction ? 'production' : 'development',
    origin: origin,
    apiRoot: apiRoot,
    websiteRoot: websiteRoot,
    websocketRoot: websocketRoot,
    appName: appName,
};
export default config;
