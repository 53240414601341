var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../hooks';
import { createGame, } from "../reducers/playerSlice";
var RulesText = function () {
    return (_jsxs("div", __assign({ className: 'mb-8' }, { children: [_jsx("p", __assign({ className: 'mb-4' }, { children: "Guessticle is a word guessing game for friends." })), _jsx("p", __assign({ className: 'mb-4' }, { children: "At the start of the game, each player gets a pool of random letters. From your unique pool of letters, you select a 5-letter secret word." })), _jsx("p", __assign({ className: 'mb-4' }, { children: "The goal of the game is to guess the secret words of the other players. The player whose secret word is guessed last wins the game." })), _jsx("p", __assign({ className: 'mb-4' }, { children: "During the process of guessing, you discover hints about which letters are in the different players' secret words. Every guess reveals information about every player's secret word \u2014 so guess carefully!" }))] })));
};
var popupBackgroundClasses = 'absolute inset-0 flex justify-center items-center bg-gray-50/25 z-20';
var popupForegroundClasses = 'bg-gray-0 rounded-sm p-10 relative flex-col flex justify-center items-center z-30';
var popupInnerClasses = 'overflow-y-scroll';
var popupForegroundStyles = {
    minHeight: '25rem',
    maxHeight: '80vh',
    width: '25rem',
    zIndex: 30,
};
var CreateNewGamePrompt = function () {
    var dispatch = useAppDispatch();
    var clickHandler = function () {
        console.log("hello");
        dispatch(createGame());
    };
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: 'text-2xl font-bold mb-4' }, { children: "Start a New Game" })), _jsxs("p", __assign({ className: 'mb-4' }, { children: ["When you start a new game, you will be given a ", _jsx("i", { children: "sharing code" }), " to send to your friends."] })), _jsx("p", __assign({ className: 'mb-8' }, { children: "Then, when your friends are at this screen, they can click \"Join a game\" and use the sharing code to join your game." })), _jsx("button", __assign({ className: 'btn relative z-40', onClick: clickHandler }, { children: "Start new game and get sharing code" }))] }));
};
var ShareNewGamePrompt = function (props) {
    var gameUrl = "".concat(window.location.origin, "/play/").concat(props.urlKey);
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: 'text-2xl font-bold mb-4' }, { children: "Your New Game" })), _jsx("p", __assign({ className: 'mb-4' }, { children: "The sharing code for your new game is:" })), _jsx("p", __assign({ className: 'mb-4 text-2xl' }, { children: props.urlKey })), _jsx("p", __assign({ className: 'mb-4' }, { children: "Send this code to your friends so they can join your game." })), _jsx("p", __assign({ className: 'mb-4' }, { children: "Or, you can send them this link:" })), _jsx("div", __assign({ className: 'mb-8 text-xl' }, { children: gameUrl })), _jsx("a", __assign({ className: 'btn', href: gameUrl, target: '_blank' }, { children: "Go to your game" }))] }));
};
export var StartNewGameWidget = function (props) {
    var dispatch = useAppDispatch();
    var bgRef = useRef(null);
    var newGame = useAppSelector(function (state) { return state.player.nextGame; });
    var sharingCode = newGame ? newGame.urlKey : null;
    var closePopup = function (e) {
        console.log(bgRef.current, e.target);
        if (bgRef.current === e.target) {
            props.close(e);
        }
    };
    return (_jsx("div", __assign({ className: popupBackgroundClasses, onClick: closePopup, ref: bgRef }, { children: _jsx("div", __assign({ className: popupForegroundClasses, style: popupForegroundStyles }, { children: sharingCode ? _jsx(ShareNewGamePrompt, { urlKey: sharingCode }) : _jsx(CreateNewGamePrompt, {}) })) })));
};
export var JoinGameWidget = function (props) {
    var bgRef = useRef(null);
    var _a = useState(''), urlKey = _a[0], setUrlKey = _a[1];
    var urlKeyPattern = /^[a-zA-Z0-9]{4}$/;
    var urlKeyInvalid = !(urlKeyPattern.test(urlKey));
    var closePopup = function (e) {
        console.log(bgRef.current, e.target);
        if (bgRef.current === e.target) {
            props.close(e);
        }
    };
    var handleUrlKeyChange = function (e) {
        setUrlKey(e.target.value);
    };
    return (_jsx("div", __assign({ className: popupBackgroundClasses, onClick: closePopup, ref: bgRef }, { children: _jsxs("div", __assign({ className: popupForegroundClasses, style: popupForegroundStyles }, { children: [_jsx("div", __assign({ className: 'text-2xl font-bold mb-4' }, { children: "Join an existing game" })), _jsx("p", { children: "Enter the 4-digit code for a game you'd like to join" }), _jsx("input", { onChange: handleUrlKeyChange, className: 'mt-4 mb-8 p-1 text-center text-5xl border border-gray-50 rounded-sm w-full uppercase', style: { letterSpacing: '0.25rem' }, type: "text", name: "urlKey", placeholder: "code", required: true }), !urlKeyInvalid && _jsx("a", __assign({ className: 'btn mb-8', href: "/play/".concat(urlKey), target: '_blank' }, { children: _jsxs(_Fragment, { children: ["Join game \u201C", _jsx("span", __assign({ className: 'uppercase' }, { children: urlKey })), "\u201D"] }) }))] })) })));
};
export var RulesWidget = function (props) {
    var bgRef = useRef(null);
    var closePopup = function (e) {
        console.log(bgRef.current, e.target);
        if (bgRef.current === e.target) {
            props.close(e);
        }
    };
    return (_jsx("div", __assign({ className: popupBackgroundClasses, onClick: closePopup, ref: bgRef }, { children: _jsx("div", __assign({ className: popupForegroundClasses, style: popupForegroundStyles }, { children: _jsxs("div", __assign({ className: popupInnerClasses }, { children: [_jsx("div", __assign({ className: 'text-2xl font-bold mb-4' }, { children: "How to play Guessticle" })), _jsx(RulesText, {}), _jsx("button", __assign({ className: 'btn', onClick: props.close }, { children: "Done reading" }))] })) })) })));
};
export var LandingPageButtons = function () {
    var _a = useState('none'), popupShown = _a[0], setPopupShown = _a[1];
    var closePopUp = function () {
        setPopupShown('none');
    };
    var showJoinGameWidget = function () {
        setPopupShown('joinGame');
    };
    var showStartNewGameWidget = function () {
        setPopupShown('startNewGame');
    };
    var showReadRulesWidget = function () {
        setPopupShown('readRules');
    };
    return (_jsxs("div", __assign({ className: 'flex justify-center flex-col md:grid grid-cols-3 mx-auto max-w-4xl' }, { children: [_jsx("div", __assign({ className: 'my-2 mx-4' }, { children: _jsx("button", __assign({ className: 'btn w-full', onClick: showJoinGameWidget }, { children: "Join a Game" })) })), _jsx("div", __assign({ className: 'my-2 mx-4' }, { children: _jsx("button", __assign({ className: 'btn w-full', onClick: showStartNewGameWidget }, { children: "Start a Game" })) })), _jsx("div", __assign({ className: 'my-2 mx-4' }, { children: _jsx("button", __assign({ className: 'btn w-full', onClick: showReadRulesWidget }, { children: "Learn the Rules" })) })), popupShown === 'joinGame' && _jsx(JoinGameWidget, { close: closePopUp }), popupShown === 'startNewGame' && _jsx(StartNewGameWidget, { close: closePopUp }), popupShown === 'readRules' && _jsx(RulesWidget, { close: closePopUp })] })));
};
export var maybeRenderLandingPage = function (store) {
    var activityData = window.activityData;
    var landingPageRootEl = document.getElementById("landingPageRoot");
    if (landingPageRootEl) {
        var landingPageRoot = ReactDOM.createRoot(landingPageRootEl);
        landingPageRoot.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(LandingPageButtons, {}) })));
    }
};
