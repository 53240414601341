var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// import { useSelector, useDispatch } from 'react-redux'
import { useAppDispatch } from '../hooks';
import { appendLetterToGuess, removeLetterFromGuess } from '../reducers/playerSlice';
var buttSty = {
    padding: '10px 14px',
    margin: '0 2px 4px',
    border: 0,
    borderRadius: '4px',
    backgroundColor: '#dadada',
    // textTransform: 'uppercase',
};
var LetterKey = function (props) {
    var dispatch = useAppDispatch();
    var onClick = function (e) {
        e.preventDefault();
        console.log("LetterKey onClick: ".concat(props.letter));
        if (props.letter === 'DEL') {
            dispatch(removeLetterFromGuess());
        }
        else {
            dispatch(appendLetterToGuess(props.letter));
        }
    };
    return (_jsx("button", __assign({ type: "button", className: 'uppercase', style: buttSty, onClick: onClick }, { children: props.letter })));
};
export var Keyboard = function () {
    // const keyOrder = [
    // 	[ 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p' ],
    // 	[ 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l' ],
    // 	[ 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'delete' ],
    // ]
    var keyOrder = [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        ['z', 'x', 'c', 'v', 'b', 'n', 'm', 'DEL'],
    ];
    return (_jsx("div", { children: keyOrder.map(function (row, i) {
            return (_jsx("div", { children: row.map(function (letter, i) {
                    return (_jsx(LetterKey, { letter: letter }, letter));
                }) }, "keyboard-row-".concat(i)));
        }) }));
};
export default Keyboard;
